module.exports = [{
      plugin: require('/builds/ross-potier/gatsby/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/ross-potier/gatsby/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/ross-potier/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
